@import '~bootstrap';
@import '~@fortawesome/fontawesome-free/css/all';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
body{
  font-family: 'DM Sans', sans-serif;
  color:#7d8185!important;
  scroll-behavior: smooth;
}
.web-bg-purple{
    background-color: #6f12c6;
}
.web-text-purple{
    color: #6e12c4;
}
.web-text-green{
    color: #008080;
}
.web-text-blue{
color: #364e72;
}
.web-text-orange{
    color: #fb5200!important;
}
.web-text-greens{
    color: #A2C000;
}
.web-bg-blue{
    background-color: #364e72!important;
}
.web-bg-green{
    background-color: #008080!important;
}
.web-bg-greens{
    background-color: #A2C000!important;
}
.web-bg-orange{
    background-color: #fb5200!important;
}
.web-text-heading2{
  color: #222429;
}
.web-text-heading3{
    color:#191e24;
}
.web-text-gray{
    color: #7d8185;
}

/* header1-section-start */
.header1{
    color: #fff;
}
.header1 p{
    margin-bottom: 0;
}/* header1-section-end */

/* header3-section-start */
.navbar-light .navbar-nav .nav-link
 {
  color: rgb(255 254 254);
  padding: 10px 15px;
  text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active
 {
  color: #dadada;
}
/* .navbar-expand-lg .navbar-nav{
  padding: 0 41px;} */
.dropdown .dropdown-menu li a{
    color:#1a1f25!important;
}
.dropdown .dropdown-menu li{
    padding: 0!important;
}
.dropdown .dropdown-menu li a:hover{
    background-color:#fb5200!important;
    color: #fff!important;
}
.dropdown .dropdown-menu li .active{
  background: #fb5200!important;
  color: #dadada!important;
}
.header3 ul .dropdown:hover .dropdown-menu-hover{
display: block;
}
.header3 button,.web-bg-section3 a,.contact-form button{ 
	transform: perspective(1px) translateZ(0);
	transition: 0.4s;
	display: inline-block;
	overflow: hidden;
	transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
}

.contact-form button:before,.header3 button::before,.web-bg-row .web-bg1 a:before,.web-bg-section3 .web-bg2 a:before,.web-bg3 a:before{
	content: "";
	position: absolute;
	z-index: -1;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0;
    background: #008080;
	clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
	transition: 0.4s;
}
.web-bg-row .web-bg1 a:before{
    background:#364e72!important;

}
.web-bg-row .web-bg2 a:before{
    background: #008080!important;
}

.web-bg-row .web-bg3 a:before{
    background: #fb5200!important;
}
.contact-form button:hover::before,.header3 button:hover::before,.web-bg-section4 button:hover::before,.web-bg-row .web-bg1:hover a:before,.web-bg-row .web-bg2:hover a:before,.web-bg-row .web-bg3:hover a:before{
	clip-path: polygon(25% -70%, 75% -70%, 120% 50%, 75% 170%, 25% 170%, -20% 50%);
}/* header3-section-end */

/* banner-section */
.home-banner{  
    background-repeat: no-repeat;
    background-image: url(home-banner.webp);
    background-size: cover;
    background-position: center center;
}

.home-banner .carousel-inner{
    border: 10px solid  #fff;
    box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
}
/* course-section-start */
.web-bg-section3{
  z-index: 1000;
  background-color: #f2f2f2;
}
.web-bg-row{
    cursor: pointer;
}
.web-bg-row:hover .web-bg1{
    border: 1px solid #364e72!important;
}
.web-bg-row:hover .web-bg2{
    border: 1px solid #008080!important;
}
.web-bg-row:hover .web-bg3{
    border: 1px solid #fb5200!important;
}
.web-bg-row:hover .web-bg3 h5{
    color:#fb5200!important;
}
.web-bg-row:hover .web-bg1 h5{
    color: #364e72!important;
}
.web-bg-row:hover .web-bg2 h5{
    color: #008080!important;
}
.web-bg-row{
    box-shadow: 0 10px 60px rgb(0 0 0 / 7%); 
}
.web-bg-row .web-bg3,.web-bg-row .web-bg2,.web-bg-row .web-bg1{
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.web-bg-row:hover .web-bg1,.web-bg-row:hover .web-bg2,.web-bg-row:hover .web-bg3,.web-bg-section4-button .web-bg-row:hover{
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
}/* course-section-end*/

/* news-section-start */
.news-section ul li::marker{
  color: #fb5200;
}/* news-section-start */

 /* about-section-start */
 .about-left img{
  border-radius: 0 50% 50% 52%;
}
.web-bg-section4{
    padding-top: 91px;
}
.overlay-anim
{
  margin-left: -124px;

}
.overlay-anim:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.overlay-anim:hover:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.intro-right-section{
  padding-right: 30px;
}
.web-bg-section4:before{
    position: absolute;
    left: -25%;
    top: -40%;
    width: 1306px;
    height: 1232px;
    background-image: url(shadow.png);
    content: "";
}
.section4-animation1{
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    left: 57%;
    top: 8%;
    -webkit-animation: moveBounce-x 10s infinite linear;
    animation: moveBounce-x 10s infinite linear;
}
@-webkit-keyframes moveBounce-x {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(30px);
              transform: translateX(30px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes moveBounce-x {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(30px);
              transform: translateX(30px);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  .section4-animation2{
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    top: -70px;
    left: 120px;
    width: 38px;
    height: 39px;
    -webkit-animation: fa-spin 10s infinite linear;
    animation: fa-spin 10s infinite linear;
}
  .web-bg-section4{
    text-align: justify;
    overflow: hidden;
  } /* about-section-end */


     /* advantage-section-start */
     .web-bg-section5{
      z-index: 100;
     }
     .web-bg-section5 .card img{
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }
    .web-bg-section5 .card:hover img{
    -webkit-filter: drop-shadow(0 20px 5px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 20px 5px rgba(0, 0, 0, 0.2));
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
    }
    .web-bg-section5 .card:hover h4{
      color: #fb5200;
    }
    .web-bg-section5 h6{
      letter-spacing: 0.1em;
    }
       /* advantage-section-end*/


/* subcategoerypage-banner-section-ourmanager,contact page-banner */
.subcategory-banner:before,.home-banner:before{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #131313;
  opacity: 0.6;
  content: "";
}
.subcategory-banner{  
  background-repeat: no-repeat;
  background-image: url(innerbg.webp);
  background-size: cover;
  background-position: center center;
  border: 10px solid  #fff;
  box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
}



/* manager-section */
.subcategory-section h6{
  letter-spacing: 0.1em;
}
.subcategory-section h5:before{
  content: '';
  position: absolute;
  left: 0;
  width: 4px;
  height: 25px;
  background-color:#fb5200;
}
/* contact */
.contact-form .form-control{
  padding: 14px 30px;
  border: none;
  background-color:rgb(14 0 27 / 6%);
}
.contact-form button:before{
  background-color: #fb5200;
}
.contact-form ul li::marker{
color: #fb5200;}

      .contact-form h6{
        letter-spacing: 0.1em;
      }
     
      .contact-form ul{
        margin-bottom: 0;
      }
      .contact-form .table-responsive .table tbody tr:hover td img{
        background-color: #fb5200!important;
        transition: all .5s;
      }
      .contact-form .table-responsive .table tbody tr:hover{
        cursor: pointer;
      }
      .contact-form .table tr{
        border-color: aliceblue!important;
      }

      .slider .carousel-item img
{
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height:50vh;
}


.inner-content h2{
  border-left: 3px solid #fb5200;
    display: inline-block;
    padding-left: 15px;
}
.inner-content h3, .inner-content h4, .inner-content h5, .inner-content h6{
  padding-top: 10px;
  color:rgb(75, 65, 65);
  font-weight: bold;
}
.inner-content ul li{
  list-style: none;
  line-height: 31px;
}

.inner-content table{
  overflow-x:auto;
}


.list-icon ul li:before{
  content: '\f19c';
  font-family: fontAwesome;
  color:#fb5200;
  margin-right: 7px;
}

.footer-section{
  overflow: hidden;
}
.footer-bg
{
  background-image: url(footer-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: shrink 5s infinite alternate;
}
@keyframes shrink {
  0% {
    background-size: 105% 105%;
  }
  100% {
    background-size: 100% 100%;
  }
}

.foot1 ul li:hover a, .footer-bg ul li:hover,.foot2 p:hover,.footer-bg p:hover,.footer-bg p a:hover {
  color: #fff!important;
  transition: all .5s ease-in-out;
}
.footer-bg ul li a{
  position: relative;
}
.footer-bg ul li a:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #fb5200;
  transition: all .5s ease-in-out;
}
.footer-bg ul li:hover a:before{
  width: 100%;
} 
.footer-bg h5{
  border-left: 5px solid #fb5200;
  color: #fff;
  letter-spacing: 0.1em;
}
.foot2{
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.foot2 p a:hover{
color: #fff!important;
}

.icon-bg
{
  background-color: #6f12c6;
  width: 70px;
  height: 80px;
  text-align: center;
}

.contact-form .address-icon:hover .icon-bg {
  background-color: #fb5200;
  transition: all .5s;
}

.contact h6{
  color:#000;
}

.foot1 h6
{
  color:#fb5200;
}

.welcome h6:before{
  content: '\f02d';
  font-family: fontAwesome;
  color:#fb5200;
  margin-right: 7px;
}

.welcome h2{
  font-size: 4.5rem;
}

.footertextcolor
{
  color:#b9b8b8;
}

.apply-btn
{
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  border: 2px solid #fb5200;
}
      
@keyframes example {
  0%   {background-color: red;}
  25%  {background-color: darkslategray;}
  50%  {background-color: blue;}
  100% {background-color: green;}
}   
 .social-icon a:hover
 {
   color:#fb5200;
 }

 .floating{
	position:fixed;
	bottom:100px;
	right:30px;
	background-color:#25d366;
	border-radius:50px;	
  z-index:1000;
  transition: .5s;
}
.floating:hover
{
    background-color:#1daf53;
}
